import * as React from 'react';

export const ScrollNavContext = React.createContext<any>(1);

const ScrollNavProvider: React.FC = ({ children }) => {
  const [activePhase, setActivePhase] = React.useState<number>(1);

  return <ScrollNavContext.Provider value={[activePhase, setActivePhase]}>{children}</ScrollNavContext.Provider>;
};

export default ScrollNavProvider;
