import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

const AuthController = () => {

    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();

    const requestAccessToken = (code: string) => {
        let code_verifier = ""
        code_verifier = window.localStorage.getItem('pkce') ?? "";

        if (code_verifier !== "") {
            code_verifier = JSON.parse(code_verifier)
            const requestHeaders: HeadersInit = new Headers();
            requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
            requestHeaders.set('Content-length', '307');

            const params: URLSearchParams = new URLSearchParams()
            params.append('grant_type', 'authorization_code')
            params.append('code', code)
            params.append('client_id', process.env.REACT_APP_SALESFORCE_CLIENT_ID ?? "")
            params.append('client_secret', process.env.REACT_APP_SALESFORCE_CLIENT_SECRET ?? "")
            const hostname = window.location.hostname;
            if (hostname === "localhost") {
                params.append('redirect_uri', 'http://localhost:3000/auth')
            } else {
                params.append('redirect_uri', `https://${hostname}/auth`)
            }
            params.append('code_verifier', code_verifier)

            fetch('https://vaisala.my.site.com/myvaisala/services/oauth2/token', {
            // fetch('https://api.vaisala.com/dev/v1/sfauth/myvaisala/services/oauth2/token', {
                method: 'POST',
                headers: requestHeaders,
                body: params.toString()
            })
            .then(response => {
                response.json().then(data =>{
                    window.localStorage.setItem('token', data.access_token)
                    const initUrl = window.localStorage.getItem('initUrl')
                    window.localStorage.removeItem('pkce');
                    if (initUrl) {
                        navigate(initUrl);
                    } else {
                        navigate('/');
                    }
                })
             })
             .catch(error => console.log(error));
        }
    }

    React.useEffect(() => {
        const code = searchParams.get('code');
        if (!code) {
            navigate('/');
        } else {
            requestAccessToken(code);

        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [searchParams, navigate]);


    return (
        <div>
            <h1>Checking for token...</h1>
        </div>
    )
}

export default AuthController