export const countDecimals = function (number: number) {
  if (Math.floor(number) === number) return 0;

  const str = number.toString();
  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
    return parseInt(str.split("-")[1]) || 0;
  } else if (str.indexOf(".") !== -1) {
    return str.split(".")[1].length || 0;
  }
  return parseInt(str.split("-")[1]) || 0;
};